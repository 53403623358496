import { css } from '@emotion/react';
import React, { memo, useEffect } from 'react';
import { fill_horizontal_cross_center, fill_vertical_main_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolSearchInput } from '../SymbolSearchInput';
import { ordersStore, StockOrderTypeLabels, StockTypeLabels, PositionType, StockType, StockOrderType, } from '../ordersStore';
import { useSnapshot } from 'valtio';
import { Button, NumberInput } from '@mantine/core';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { formatStockDecimal, formatStockPrice, formatStockStep } from '../utils/formatStockTradinfo';
export const TWStockOrder = memo(function TWStockOrder() {
    const orderParams = useSnapshot(ordersStore);
    /** 商品代碼 */
    const symbolNumber = orderParams.orderSymbol.replace(/\D/g, '');
    useEffect(() => {
        signalrStore2.addQuote(symbolNumber);
        return () => {
            signalrStore2.removeQuote(symbolNumber);
        };
    }, [JSON.stringify(symbolNumber)]);
    const value = useSnapshot(signalrStore2.values.quote)[symbolNumber];
    /** 當商品變動時 無商品：將委託價格歸零 有商品：將委託價更新該商品最新成交價 */
    useEffect(() => {
        ordersStore.orderPrice = value?.close ?? 0;
    }, [value?.close]);
    /** 顯示整股與零股單位 `1000股` `1股` */
    const orderUnit = orderParams.stockOrderType.includes('WholeStock') ? 1000 : 1;
    /** 顯示買進數量 依據整股與零股做轉換 */
    const qty = orderParams.orderQty * orderUnit;
    /** 顯示買進預估成本 */
    const amount = Math.ceil(orderParams.orderPrice * qty);
    return (<div css={classes.container}>
      {/** 帳號列 */}
      <div css={classes.content}>
        <label css={classes.label}>帳號</label>
        <div>模擬帳號-44283915</div>
      </div>
      {/** 商品搜尋與顯示 */}
      <div css={classes.content}>
        <label css={classes.label}>商品搜尋</label>
        <SymbolSearchInput />
        <div>{fr_instrument.getName(value?.symbol ?? '')}</div>
        <div onClick={() => (ordersStore.orderSymbol = '')}>清除</div>
      </div>
      {/** 交易股票種類 整股/零股 */}
      <div css={classes.content}>
        <label css={classes.label}>交易類別</label>
        {Object.values(StockOrderType).map(type => (<div key={type} onClick={() => (ordersStore.stockOrderType = type)} css={orderParams.stockOrderType === type ? classes.selected : ''}>
            {StockOrderTypeLabels[type]}
          </div>))}
      </div>
      {/** 交易商品類型 現股/融資/融券/借券 */}
      <div css={classes.content}>
        <label css={classes.label}>下單類型</label>
        {Object.values(StockType).map(type => (<div key={type} onClick={() => (ordersStore.stockType = type)} css={orderParams.stockType === type ? classes.selected : ''}>
            {StockTypeLabels[type]}
          </div>))}
      </div>
      {/** 委託單 ROD/IOC/FOK */}
      <div css={classes.content}>
        <label css={classes.label}>委託類別</label>
        {Object.values(PositionType).map(type => (<div key={type} onClick={() => (ordersStore.positionType = type)} css={orderParams.positionType === type ? classes.selected : ''}>
            {type}
          </div>))}
      </div>
      {/** 數量 */}
      <div css={classes.content}>
        <label css={classes.label}>數量</label>
        <NumberInput value={orderParams.orderQty} onChange={e => (ordersStore.orderQty = e)} min={1} max={orderUnit === 1 ? 999 : 499}/>
        <div>交易單位 {qty.toLocaleString()} 股</div>
      </div>
      {/** 價格 */}
      <div css={classes.content}>
        <label css={classes.label}>價格</label>
        <NumberInput defaultValue={formatStockPrice(value?.close ?? 0)} value={formatStockPrice(orderParams.orderPrice)} onChange={e => (ordersStore.orderPrice = e)} precision={formatStockDecimal(orderParams.orderPrice)} step={formatStockStep(ordersStore.orderPrice)} min={0}/>
        <div>預估成本 {amount.toLocaleString()}</div>
      </div>
      {/** 常用價格 */}
      <div css={classes.content}>
        <Button onClick={() => (ordersStore.orderPrice = value?.close ?? 0)} color='blue' variant='outline'>
          市價
        </Button>
        <Button onClick={() => (ordersStore.orderPrice = value?.limitUpPrice ?? 0)} color='red' variant='outline'>
          漲停
        </Button>
        <Button onClick={() => (ordersStore.orderPrice = value?.limitDownPrice ?? 0)} color='green' variant='outline'>
          跌停
        </Button>
        <Button onClick={() => (ordersStore.orderPrice = value?.open ?? 0)} color='gray' variant='outline'>
          平盤
        </Button>
      </div>
      {/** 下單方向 */}
      <div css={classes.content}>
        {/* <label css={classes.label}>下單方向</label> */}

        <Button onClick={() => (ordersStore.orderAction = 'Buy')} color='red' css={css `
            width: 100%;
          `}>
          買進
        </Button>
        <Button onClick={() => (ordersStore.orderAction = 'Sell')} color='green' css={css `
            width: 100%;
          `}>
          賣出
        </Button>
      </div>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_main_center};
    gap: 8px;
  `,
    content: css `
    ${fill_horizontal_cross_center};
    height: 36px;
    gap: 8px;
  `,
    label: css `
    font-weight: 500;
  `,
    selected: css `
    font-weight: bold;
    color: blue;
  `,
};
