import { proxy } from 'valtio';
/** 下單市場類別 */
export var OrderCategory;
(function (OrderCategory) {
    /** 國內證券 */
    OrderCategory["TWStock"] = "TWStock";
    /** 國內期貨 */
    OrderCategory["TWFutures"] = "TWFutures";
    /** 國內選擇權 */
    OrderCategory["TWOption"] = "TWOption";
    /** 國外期貨 */
    OrderCategory["OSFutures"] = "OSFutures";
})(OrderCategory || (OrderCategory = {}));
/** 委託單種類 */
export var PositionType;
(function (PositionType) {
    /** 當日有效 */
    PositionType["ROD"] = "ROD";
    /** 立即部分成交否則取消 */
    PositionType["IOC"] = "IOC";
    /** 立即全部成交否則取消 */
    PositionType["FOK"] = "FOK";
})(PositionType || (PositionType = {}));
/** 下單方向 */
export var OrderAction;
(function (OrderAction) {
    /** 買進 */
    OrderAction["Long"] = "Long";
    /** 賣出 */
    OrderAction["Short"] = "Short";
})(OrderAction || (OrderAction = {}));
/** 股票交易類別 整股/零股 */
export var StockOrderType;
(function (StockOrderType) {
    /** 整股 */
    StockOrderType["WholeStock"] = "WholeStock";
    /** 零股 */
    StockOrderType["OddLot"] = "OddLot";
    /** 盤後整股 */
    StockOrderType["AfterMarketWholeStock"] = "AfterMarketWholeStock";
    /** 盤後零股 */
    StockOrderType["AfterMarketOddLot"] = "AfterMarketOddLot";
})(StockOrderType || (StockOrderType = {}));
/** 股票商品種類 現股/融資/融券/借券 */
export var StockType;
(function (StockType) {
    /** 現股 */
    StockType["CashStock"] = "CashStock";
    /** 融資 */
    StockType["MarginTrading"] = "MarginTrading";
    /** 融券 */
    StockType["ShortSelling"] = "ShortSelling";
    /** 借券 */
    StockType["SecuritiesLending"] = "SecuritiesLending";
})(StockType || (StockType = {}));
/** 期貨交易類別 */
export var FuturesOrderType;
(function (FuturesOrderType) {
    /** 自動 */
    FuturesOrderType["Auto"] = "Auto";
    /** 新倉 */
    FuturesOrderType["NewPosition"] = "NewPosition";
    /** 平倉 */
    FuturesOrderType["ClosePosition"] = "ClosePosition";
    /** 當沖 */
    FuturesOrderType["DayTrading"] = "DayTrading";
})(FuturesOrderType || (FuturesOrderType = {}));
/** 選擇權交易類別 */
export var OptionStructureType;
(function (OptionStructureType) {
    /** 單式 */
    OptionStructureType["SingleLeg"] = "SingleLeg";
    /** 複式 */
    OptionStructureType["MultiLeg"] = "MultiLeg";
})(OptionStructureType || (OptionStructureType = {}));
// 選擇權複式單組合判斷整體方向(給使用者友善判別)
export var OptionMainStrategyType;
(function (OptionMainStrategyType) {
    /** 看多 */
    OptionMainStrategyType["Bullish"] = "Bullish";
    /** 看空 */
    OptionMainStrategyType["Bearish"] = "Bearish";
    /** 突破 */
    OptionMainStrategyType["Breakout"] = "Breakout";
    /** 盤整 */
    OptionMainStrategyType["Consolidation"] = "Consolidation";
})(OptionMainStrategyType || (OptionMainStrategyType = {}));
// 選擇權複式單組合策略名稱(給使用者友善判別)
export var OptionSubStrategyType;
(function (OptionSubStrategyType) {
    //---看多策略---
    /** 買權多頭價差 @example Buy Call低履約價 / Sell Call高履約價 */
    OptionSubStrategyType["LongCallSpread"] = "LongCallSpread";
    /** 賣權多頭價差 @example Sell Put高履約價 / Buy Put低履約價 */
    OptionSubStrategyType["LongPutSpread"] = "LongPutSpread";
    /** 逆轉組合 @example Buy Call同履約價 / Sell Put同履約價 */
    OptionSubStrategyType["ReverseCombo"] = "ReverseCombo";
    //---看空策略---
    /** 賣權空頭價差 @example Buy Put高履約價 / Sell Put低履約價 */
    OptionSubStrategyType["ShortPutSpread"] = "ShortPutSpread";
    /** 買權空頭價差 @example Sell Call低履約價 / Buy Call高履約價 */
    OptionSubStrategyType["ShortCallSpread"] = "ShortCallSpread";
    /** 轉換組合 @example Sell Call同履約價 / Buy Put同履約價 */
    OptionSubStrategyType["ConversionCombo"] = "ConversionCombo";
    //---突破策略---
    /** 買進跨式 @example Buy Call同履約價 / Buy Put同履約價 */
    OptionSubStrategyType["LongStraddle"] = "LongStraddle";
    /** 買進勒式 @example Buy Call高履約價 / Buy Put低履約價 */
    OptionSubStrategyType["LongStrangle"] = "LongStrangle";
    //---盤整策略---
    /** 賣出跨式 @example Sell Call同履約價 / Sell Put同履約價 */
    OptionSubStrategyType["ShortStraddle"] = "ShortStraddle";
    /** 賣出勒式 @example Sell Call高履約價 / Sell Put低履約價 */
    OptionSubStrategyType["ShortStrangle"] = "ShortStrangle";
})(OptionSubStrategyType || (OptionSubStrategyType = {}));
export const ordersStore = proxy({
    /** @Common */
    orderCategory: OrderCategory.TWStock,
    orderSymbol: '',
    positionType: PositionType.ROD,
    orderQty: 1,
    orderPrice: 0,
    orderAction: OrderAction.Long,
    /** @Stock */
    stockOrderType: StockOrderType.WholeStock,
    stockType: StockType.CashStock,
    /** @Futures */
    futuresOrderType: FuturesOrderType.Auto,
    /** @Options */
    optionType: OptionStructureType.SingleLeg,
    optionMainStrategyType: OptionMainStrategyType.Bullish,
    optionSubStrategyType: OptionSubStrategyType.LongCallSpread,
});
export const StockOrderTypeLabels = {
    [StockOrderType.WholeStock]: '整股',
    [StockOrderType.OddLot]: '零股',
    [StockOrderType.AfterMarketWholeStock]: '盤後整股',
    [StockOrderType.AfterMarketOddLot]: '盤後零股',
};
export const OrderActionLabels = {
    [OrderAction.Long]: '買進',
    [OrderAction.Short]: '賣出',
};
export const StockTypeLabels = {
    [StockType.CashStock]: '現股',
    [StockType.MarginTrading]: '融資',
    [StockType.ShortSelling]: '融券',
    [StockType.SecuritiesLending]: '借券',
};
