/** 顯示股票小數位第幾位 eg.`2` */
export const formatStockDecimal = (value) => {
    let decimalPlaces = 2;
    if (value >= 10 && value < 50) {
        decimalPlaces = 2;
    }
    else if (value >= 50 && value < 100) {
        decimalPlaces = 1;
    }
    else if (value >= 100 && value < 500) {
        decimalPlaces = 1;
    }
    else if (value >= 500 && value < 1000) {
        decimalPlaces = 0;
    }
    else if (value >= 1000) {
        decimalPlaces = 0;
    }
    return decimalPlaces;
};
/** 顯示股票價格(包含小數位價格) eg.`50.2` */
export const formatStockPrice = (value) => {
    let factor = 1;
    if (value >= 0 && value < 50) {
        factor = 100;
    }
    else if (value >= 50 && value < 500) {
        factor = 10;
    }
    else if (value >= 1000) {
        factor = 1;
    }
    const roundedValue = Math.round(value * factor) / factor;
    return roundedValue;
};
/** 顯示股票最小跳動點 eg.`0.05` */
export const formatStockStep = (value) => {
    let step = 0.01;
    if (value >= 10 && value < 50) {
        step = 0.05;
    }
    else if (value >= 50 && value < 100) {
        step = 0.1;
    }
    else if (value >= 100 && value < 500) {
        step = 0.5;
    }
    else if (value >= 500 && value < 1000) {
        step = 1;
    }
    else if (value >= 1000) {
        step = 5;
    }
    return step;
};
/** 計算兩者價格之間跳動點 eg.`2` */
export const calculateStockSteps = (price1, price2) => {
    if (price1 > price2) {
        ;
        [price1, price2] = [price2, price1];
    }
    let steps = 0;
    let currentPrice = price1;
    const tolerance = 0.00001; // Define a small tolerance
    while (currentPrice + tolerance < price2) {
        const stepUnit = formatStockStep(currentPrice); // Assume this returns the correct step size
        currentPrice = Math.min(currentPrice + stepUnit, price2);
        steps++;
    }
    return steps;
};
