import { useDisclosure } from '@mantine/hooks';
import { Drawer, Button } from '@mantine/core';
import { memo } from 'react';
import { OrderDashboard } from './OrderDashboard';
import { OrderStatusDashboard } from './OrderStatusDashboard';
import { css } from '@emotion/react';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
export const OrderDrawerButton = memo(function OrderDrawerButton() {
    const [opened, { open, close }] = useDisclosure(false);
    useSignalrStart2_1();
    return (<div>
      <Drawer opened={opened} onClose={close} position='right' title='order-drawer-title' css={css `
          .mantine-1q36a81:not(:only-child) {
            height: calc(100% - 54px);
          }
        `}>
        <div css={classes.container}>
          <OrderDashboard />
          <OrderStatusDashboard />
        </div>
      </Drawer>

      <Button onClick={open}>下單匣</Button>
    </div>);
});
const classes = {
    container: css `
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 100%;
  `,
};
