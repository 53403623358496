import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
/** google標單填寫預約開戶服務 */
//https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform
export const FormOpenAccount = memo(function FormOpenAccount(props) {
    return (<classes.container css={props.containerCss}>
      <classes.title>便利下單功能申請</classes.title>
      <Button css={css `
          padding: 2px;
          gap: 4px;
          border-radius: 5px;
          cursor: pointer;
          height: 100%;
          background-color: rgb(76, 86, 95);
          line-height: 18px;
          transition: 0.3s;
          &:hover {
            background-color: #578aef;
          }
        `}>
        <a href={props.customURL} target={'_blank'} rel='noreferrer' css={css `
            color: #ffffff;
          `}>
          確認
        </a>
      </Button>
    </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 80% 20%;
    height: 40px;
    width: 100%;
    border-radius: 4px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    font-size: 14px;
    padding: 8px 0;
  `,
};
