import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider } from '@mantine/core';
import { memo, useEffect } from 'react';
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useVolumeMa, usePercentRangeResource, useBig3ConsecutiveResource, useMonthlyRevenueGrowth, } from '~/modules/screener/containers/useStockScreenerResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { a2330_store } from '~/pages/a2330/a2330_store';
const TypeBotton = memo(function TypeBotton(props) {
    const state = useSnapshot(a2330_store);
    const displayType = props.type === 'long' ? '多' : '空';
    const selected = props.type === state.stockSelectionType;
    return (<styleds.button selected={selected} className={props.type} onClick={() => (a2330_store.stockSelectionType = props.type)}>
      {displayType}
    </styleds.button>);
});
const SelectCard = memo(function SelectCard(props_) {
    const state = useSnapshot(a2330_store);
    const handleConditionToggle = (condition) => {
        // 老師精選的股票須要另外處理（排序在最上面等），不受條件影響
        if (condition === 'firebaseSymbol') {
            a2330_store.stockSelectedFirebase = !a2330_store.stockSelectedFirebase;
            return;
        }
        if (state.stockSelectionCondition.includes(condition)) {
            a2330_store.stockSelectionCondition = state.stockSelectionCondition.filter(s => s !== condition);
        }
        else {
            a2330_store.stockSelectionCondition = [...state.stockSelectionCondition, condition];
        }
    };
    const selected = props_.a === 'firebaseSymbol'
        ? state.stockSelectedFirebase
        : state.stockSelectionCondition.includes(props_.a);
    return (<styleds.selectCard backgroundColor={props_.backgroundColor} selected={selected} className={state.stockSelectionType} onClick={() => {
            handleConditionToggle(props_.a);
            // 在切換條件的時候把搜尋的股票清空，避免搜尋的股票與選擇的股票不一致
            a2330_store.searchedSymbol = [];
        }}>
        {selected ? <BsCheckCircleFill /> : <BsCheckCircle />}
        {props_.children}
      </styleds.selectCard>);
});
export const StockSelectionBoard = memo(function StockSelectionBoard() {
    const state = useSnapshot(a2330_store);
    const baseDate = useSnapshot(staticStore).tradedDate.day0;
    const priceStatus = state.stockSelectionType === 'long' ? 'new_high' : 'new_low';
    const big3Action = state.stockSelectionType === 'long' ? 'buy' : 'sell';
    const monthlyRevenueGrowthDirection = state.stockSelectionType === 'long' ? 'up' : 'down';
    /** 依照交易量篩選當作基本股池(5日平均均量為500張) */
    const baseSymbol = useVolumeMa({
        date: baseDate.format('YYYY-MM-DD'),
        ma: 5,
        volume_gte: 500,
    }).data;
    /** 技術面：今日高/低點有突破30日最高/低價 */
    const priceBreakoutPointSymbol = usePercentRangeResource({
        days: 30,
        status: priceStatus,
    }).data?.map(datum => datum.symbol);
    // 籌碼面：單日三大法人買買超
    const big3TradingSymbol = useBig3ConsecutiveResource({
        date: baseDate.format('YYYY-MM-DD'),
        days: 1,
        action: big3Action,
        institution: 'all',
    }).data?.map(datum => datum.symbol);
    /** 基本面：最新一期的月營收比較去年同月份增加/減少的標的 */
    const financialReportSymbol = useMonthlyRevenueGrowth({
        target: 'yoy',
        change: monthlyRevenueGrowthDirection,
    }).data;
    useEffect(() => {
        if (state.stockSelectionCondition.length >= 1) {
            const selectedSymbols = state.stockSelectionCondition.map(condition => {
                switch (condition) {
                    case 'priceBreakoutPointSymbol':
                        return priceBreakoutPointSymbol ?? [];
                    case 'financialReport':
                        return financialReportSymbol ?? [];
                    case 'big3TradingSymbol':
                        return big3TradingSymbol ?? [];
                    default:
                        return [];
                }
            });
            // 找到所有選擇條件的共同股票代號
            const commonSymbols = selectedSymbols.reduce((acc, symbols) => acc.filter(symbol => symbols.includes(symbol)));
            // 更新 a2330_store.stockSymbol
            a2330_store.stockSymbol = commonSymbols.filter(symbol => baseSymbol?.includes(symbol)) || [];
        }
        else {
            a2330_store.stockSymbol = [];
        }
    }, [JSON.stringify(state.stockSelectionCondition), JSON.stringify(state.stockSelectionType)]);
    return (<styleds.container>
      <styleds.typeContent>
        <TypeBotton type={'long'}>多方</TypeBotton>
        <TypeBotton type={'short'}>空方</TypeBotton>
      </styleds.typeContent>
      <styleds.title>
        <Divider css={css `
            width: 100%;
          `} my='sm' labelPosition='center' label='條件選擇(最少兩個條件)'/>
      </styleds.title>
      {state.stockSelectionType === 'long' && (<styleds.itemContent>
          <SelectCard a={'financialReport'} backgroundColor={'#00cc22'}>
            基本面
          </SelectCard>
          <SelectCard a={'big3TradingSymbol'} backgroundColor={'#ffee00fb'}>
            籌碼面
          </SelectCard>
          <SelectCard a={'priceBreakoutPointSymbol'} backgroundColor={'#ff0000'}>
            技術面
          </SelectCard>
          <SelectCard a={'firebaseSymbol'} backgroundColor={'#00e5ff'}>
            AI精選
          </SelectCard>
        </styleds.itemContent>)}
      {state.stockSelectionType === 'short' && (<styleds.itemContent>
          <SelectCard a={'financialReport'} backgroundColor={'#00cc22'}>
            基本面
          </SelectCard>
          <SelectCard a={'big3TradingSymbol'} backgroundColor={'#ffee00fb'}>
            籌碼面
          </SelectCard>
          <SelectCard a={'priceBreakoutPointSymbol'} backgroundColor={'#ff0000'}>
            技術面
          </SelectCard>
          <SelectCard a={'firebaseSymbol'} backgroundColor={'#00e5ff'}>
            AI精選
          </SelectCard>
        </styleds.itemContent>)}
    </styleds.container>);
});
const styleds = {
    //button---------------------------
    button: styled.div `
    ${fill_vertical_all_center};
    background-color: #2f2f2f;
    color: ${props => (props.selected ? '#fafafa' : '#252525')};
    border: 1px solid ${props => (props.selected === true ? '#252525' : '#acacac')};
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    &.long {
      background-color: ${props => (props.selected === true ? '#bb0000' : '#fafafa')};
    }
    &.short {
      background-color: ${props => (props.selected === true ? '#00bb00' : '#fafafa')};
    }
    transition: 0.3s;
  `,
    //board----------------------------
    selectCard: styled.div `
    ${fill_vertical_all_center};
    height: 50px;
    border: 1px solid ${props => (props.selected ? '#acacac' : '#252525')};
    color: ${props => (props.selected ? '#686868' : '#252525')};
    border-radius: 4px;
    font-size: 13px;
    padding: 4px 2px;
    cursor: pointer;
    transition: 0.3s;
    gap: 8px;
    &:hover {
      margin-top: 4px;
    }
    background-color: ${props => (props.selected ? props.backgroundColor : '#fafafa')};
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
    /* background-color: #25262a; */
    height: 130px;
    padding: 2px;
    user-select: none;
  `,
    title: styled.div `
    ${fill_vertical_all_center};
    height: 30px;
    font-size: 14px;
  `,
    typeContent: styled.div `
    ${fill_horizontal_all_center};
    height: 36px;
    gap: 4px;
    padding: 2px;
  `,
    itemContent: styled.div `
    ${fill_horizontal_all_center};
    height: 50px;
    gap: 4px;
    padding: 0px 2px;
  `,
};
