import { Autocomplete } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { ordersStore } from './ordersStore';
import { useSnapshot } from 'valtio';
export const SymbolSearchInput = memo(function SymbolSearchInput() {
    const [inputValue, setInputValue] = useState('');
    const orderParams = useSnapshot(ordersStore);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    useEffect(() => {
        if (orderParams.orderSymbol === '') {
            setInputValue('');
        }
    }, [orderParams.orderSymbol]);
    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            const isSymbolInList = searchDefaultSymbols.some(s => s.symbol === orderParams.orderSymbol);
            if (isSymbolInList) {
                ordersStore.orderSymbol = inputValue;
            }
            // if (isSymbolInList) {
            //   ordersStore.orderSymbol = ''
            // }
        }
    };
    const handleSubmit = (symbol) => {
        const isSymbolInList = searchDefaultSymbols.some(s => s.symbol === orderParams.orderSymbol);
        ordersStore.orderSymbol = symbol;
        if (isSymbolInList) {
            ordersStore.orderSymbol = symbol;
        }
    };
    return (<Autocomplete placeholder='搜尋代號或名稱' data={searchDefaultSymbols.map(s => s.symbol + s.name)} maxDropdownHeight={200} limit={100} value={inputValue} onChange={(value) => setInputValue(value)} onItemSubmit={item => handleSubmit(item.value)} onKeyDown={event => handleEnterPress(event)}/>);
});
