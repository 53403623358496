import { css } from '@emotion/react';
import React, { useState, memo } from 'react';
// 定義國內期貨相關的枚舉和類型
var OrderCategory;
(function (OrderCategory) {
    OrderCategory["TWFutures"] = "\u570B\u5167\u671F\u8CA8";
})(OrderCategory || (OrderCategory = {}));
var FuturesOrderType;
(function (FuturesOrderType) {
    FuturesOrderType["Auto"] = "\u81EA\u52D5";
    FuturesOrderType["NewPosition"] = "\u65B0\u5009";
    FuturesOrderType["ClosePosition"] = "\u5E73\u5009";
    FuturesOrderType["DayTrading"] = "\u7576\u6C96";
})(FuturesOrderType || (FuturesOrderType = {}));
var FuturesEntrustType;
(function (FuturesEntrustType) {
    FuturesEntrustType["ROD"] = "ROD";
    FuturesEntrustType["IOC"] = "IOC";
    FuturesEntrustType["FOK"] = "FOK";
})(FuturesEntrustType || (FuturesEntrustType = {}));
var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Long"] = "\u591A";
    OrderDirection["Short"] = "\u7A7A";
})(OrderDirection || (OrderDirection = {}));
export const TWFuturesOrder = memo(function TWFuturesOrder() {
    const [orderParams, setOrderParams] = useState({
        orderCategory: OrderCategory.TWFutures, // 固定為國內期貨
        account: '',
        product: '',
        orderType: FuturesOrderType.Auto,
        entrustType: FuturesEntrustType.ROD,
        quantity: 0,
        price: 0,
        orderDirection: OrderDirection.Long,
    });
    const handleInputChange = (field, value) => {
        setOrderParams(prev => ({
            ...prev,
            [field]: value,
        }));
    };
    return (<div>
      <div>
        <label>帳號:</label>
        <input type='text' value={orderParams.account} onChange={e => handleInputChange('account', e.target.value)}/>
      </div>
      <div>
        <label>商品搜尋:</label>
        <input type='text' value={orderParams.product} onChange={e => handleInputChange('product', e.target.value)}/>
      </div>
      <div>
        <label>下單類型:</label>
        <select value={orderParams.orderType} onChange={e => handleInputChange('orderType', e.target.value)}>
          {Object.values(FuturesOrderType).map(type => (<option key={type} value={type}>
              {type}
            </option>))}
        </select>
      </div>
      <div>
        <label>委託類別:</label>
        <select value={orderParams.entrustType} onChange={e => handleInputChange('entrustType', e.target.value)}>
          {Object.values(FuturesEntrustType).map(type => (<option key={type} value={type}>
              {type}
            </option>))}
        </select>
      </div>
      <div>
        <label>數量:</label>
        <input type='number' value={orderParams.quantity} onChange={e => handleInputChange('quantity', Number(e.target.value))}/>
      </div>
      <div>
        <label>價格:</label>
        <input type='number' value={orderParams.price} onChange={e => handleInputChange('price', Number(e.target.value))}/>
      </div>
      <div>
        <label>下單方向:</label>
        <select value={orderParams.orderDirection} onChange={e => handleInputChange('orderDirection', e.target.value)}>
          {Object.values(OrderDirection).map(direction => (<option key={direction} value={direction}>
              {direction}
            </option>))}
        </select>
      </div>
    </div>);
});
const classes = {
    container: css ``,
};
