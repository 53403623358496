import { memo } from 'react';
import { Tabs } from '@mantine/core';
import { TWStockOrder } from './TWStockOrder';
import { TWFuturesOrder } from './TWFuturesOrder';
import { TWOptionOrder } from './TWOptionOrder';
import { ordersStore } from '../ordersStore';
export const OrderDashboard = memo(function OrderDashboard() {
    return (<div>
      <Tabs variant='outline' defaultValue='tw-stock'>
        <Tabs.List>
          <Tabs.Tab value='tw-stock' onClick={() => (ordersStore.orderCategory = 'TWStock')}>
            國內證券
          </Tabs.Tab>
          <Tabs.Tab value='tw-futures' onClick={() => (ordersStore.orderCategory = 'TWFutures')}>
            國內期貨
          </Tabs.Tab>
          <Tabs.Tab value='tw-option' onClick={() => (ordersStore.orderCategory = 'TWOption')}>
            國內選擇權
          </Tabs.Tab>
          <Tabs.Tab value='os-futures' onClick={() => (ordersStore.orderCategory = 'OSFutures')}>
            海外期貨
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='tw-stock'>
          <TWStockOrder />
        </Tabs.Panel>
        <Tabs.Panel value='tw-futures'>
          <TWFuturesOrder />
        </Tabs.Panel>
        <Tabs.Panel value='tw-option'>
          <TWOptionOrder />
        </Tabs.Panel>
        <Tabs.Panel value='os-futures'>海外期貨</Tabs.Panel>
      </Tabs>
    </div>);
});
