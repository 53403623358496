import { Tabs } from '@mantine/core';
import { memo } from 'react';
import { ordersStore } from '../ordersStore';
import { useSnapshot } from 'valtio';
export const OrderStatusDashboard = memo(function OrderStatusDashboard() {
    const orderParams = useSnapshot(ordersStore);
    return (<div>
      <Tabs variant='outline' defaultValue='1'>
        <Tabs.List>
          <Tabs.Tab value='1' onClick={() => null}>
            委託回報
          </Tabs.Tab>
          <Tabs.Tab value='2' onClick={() => null}>
            成交回報
          </Tabs.Tab>
          <Tabs.Tab value='3' onClick={() => null}>
            部位查詢
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='1'>
          <div>{JSON.stringify(orderParams, null, 2)}</div>
        </Tabs.Panel>
        <Tabs.Panel value='2'>成交回報</Tabs.Panel>
        <Tabs.Panel value='3'>部位查詢</Tabs.Panel>
      </Tabs>
    </div>);
});
