import React, { memo, useEffect } from 'react';
import { Table } from '@mantine/core';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import { css } from '@emotion/react';
import _ from 'lodash';
import dayAPI from '~/utils/dayAPI';
import { calculateStockSteps, formatStockDecimal } from '../utils/formatStockTradinfo';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
export const PreMarketList = memo(function PreMarketList() {
    useSignalrStart2_1();
    useEffect(() => {
        signalrStore2.addQuoteTopic(SignalrTopic.pre_open);
        return () => {
            signalrStore2.removeQuoteTopic(SignalrTopic.pre_open);
        };
    }, []);
    const staticState = staticStore.tradedDate.intraday;
    const quoteValues = useSnapshot(signalrStore2.values.quote);
    const stockData = Object.entries(quoteValues)
        .filter(([symbol, item]) => item && /^[0-9]{4}$/.test(symbol))
        .map(([symbol, item]) => ({ symbol, ...item }))
        .slice(0, 100);
    const hotFuturesData = Object.entries(quoteValues)
        .filter(([symbol, item]) => symbol.includes('-1') && item)
        .map(([symbol, item]) => ({ symbol, ...item }));
    const mergedData = hotFuturesData.map(futures => {
        const matchingStock = stockData.find(stock => stock.symbol === fr_instrument.getUnderlying(futures.symbol)?.symbol &&
            dayAPI(futures.datetime).formatDate() === dayAPI().formatDate());
        if (matchingStock) {
            //backwardation 逆價差
            //forwardation 正價差
            const indexClose = matchingStock.close ?? 0;
            const indexLimitUp = matchingStock.limitUpPrice ?? 0;
            const indexLimitDn = matchingStock.limitDownPrice ?? 0;
            const futuresClose = futures.close ?? 0;
            const futuresLimitUp = futures.limitUpPrice ?? 0;
            const futuresLimitDn = futures.limitDownPrice ?? 0;
            const futuresVolume = futures.volume ?? 0;
            const futuresAsk = futures.ask ?? 0;
            const futuresBid = futures.bid ?? 0;
            const futuresOrderDiffTicks = calculateStockSteps(futuresAsk, futuresBid);
            const priceDiff = futuresClose - indexClose;
            const priceDiffTicks = calculateStockSteps(futuresClose, indexClose);
            const priceDiffPercent = futuresAsk !== 0 ? (1 - indexClose / futuresClose) * 100 : 0;
            const isBackwardation = priceDiff < 0;
            const tradingTicks = isBackwardation
                ? calculateStockSteps(futuresAsk, indexClose)
                : calculateStockSteps(indexClose, futuresBid);
            const futuresDateTime = dayAPI(futures.datetime).formatDateTimeSeconds() ?? '';
            return {
                futures: futures.symbol,
                futuresClose,
                futuresVolume,
                futuresLimitUp,
                futuresLimitDn,
                futuresAsk,
                futuresBid,
                futuresOrderDiffTicks,
                futuresDateTime,
                //----------------------------------------------------------------
                index: matchingStock.symbol,
                indexClose,
                indexLimitUp,
                indexLimitDn,
                //----------------------------------------------------------------
                priceDiff: Math.round(priceDiffPercent * 100) / 100,
                priceDiffTicks,
                tradingTicks: Math.round(tradingTicks * 100) / 100,
            };
        }
        return {
            futures: futures.symbol,
            futuresClose: NaN,
            futuresVolume: NaN,
            futuresLimitUp: NaN,
            futuresLimitDn: NaN,
            futuresAsk: NaN,
            futuresBid: NaN,
            futuresOrderDiffTicks: NaN,
            futuresDateTime: '',
            index: '',
            indexClose: NaN,
            indexLimitUp: NaN,
            indexLimitDn: NaN,
            priceDiff: NaN,
            priceDiffTicks: NaN,
            tradingTicks: NaN,
        };
    });
    const positiveData = _.sortBy(mergedData.filter(item => item.priceDiff > 0 && item.tradingTicks > 2), item => item.priceDiffTicks).reverse();
    const negativeData = _.sortBy(mergedData.filter(item => item.priceDiff < 0 && item.tradingTicks > 2), item => item.priceDiffTicks).reverse();
    const RenderRows = memo(function RenderRows(props) {
        return props.data.map((item, index) => {
            const indexClose = item.indexClose.toFixed(formatStockDecimal(item.indexClose));
            const futuresClose = item.futuresClose.toFixed(formatStockDecimal(item.futuresClose));
            const futuresBid = item.futuresBid.toFixed(formatStockDecimal(item.futuresBid));
            const futuresAsk = item.futuresAsk.toFixed(formatStockDecimal(item.futuresAsk));
            const diffTime = dayAPI().diff(item.futuresDateTime);
            const updateTime = dayAPI(diffTime).unix();
            const displayUpdateTime = updateTime > 300 ? '5分鐘前' : updateTime + '秒前';
            return (<tr key={`${item.index}-${index}`} css={getPriceDiffPercentStyle2(item.tradingTicks, item.priceDiff)}>
          <td css={getPriceDiffPercentStyle(item.tradingTicks, item.priceDiff)}>
            {item.index}
            {fr_instrument.getName(item.index)}/{item.futures.replace('-1', '')}
            {fr_instrument.getName(item.futures)}
          </td>
          <td css={getIndexCloseStyle(item.indexLimitUp, item.indexLimitDn, item.indexClose)}>
            {indexClose}
          </td>
          <td css={getIndexCloseStyle(item.futuresLimitUp, item.futuresLimitDn, item.futuresClose)}>
            {futuresClose}
          </td>
          <td>{item.priceDiffTicks}</td>
          <td>{item.tradingTicks}</td>
          <td css={getOrderDiffTicksStyle(item.futuresOrderDiffTicks)}>
            {item.futuresOrderDiffTicks}
          </td>
          <td>{item.futuresVolume}</td>
          <td>{futuresBid}</td>
          <td>{futuresAsk}</td>
          <td>{item.futuresDateTime.slice(5)}</td>
          <td>{displayUpdateTime}</td>
        </tr>);
        });
    });
    const header = (<thead>
      <tr css={css `
          background-color: #343434;
        `}>
        <th>現貨名稱/期貨代碼</th>
        <th>現貨價格</th>
        <th>期貨價格</th>
        <th>價差Tick</th>
        <th>價差機會</th>
        <th>掛差Tick</th>
        <th>期貨總量</th>
        <th>期委買1</th>
        <th>期委賣1</th>
        <th>期貨時間</th>
        <th>更新於</th>
      </tr>
    </thead>);
    return (<div css={containerStyle}>
      <div css={gridContainerStyle}>
        <div css={scrollbar2Css}>
          <Table>
            {header}
            <tbody>
              <RenderRows data={negativeData}/>
            </tbody>
          </Table>
        </div>
        <div css={scrollbar2Css}>
          <Table>
            {header}
            <tbody>
              <RenderRows data={positiveData}/>
            </tbody>
          </Table>
        </div>
      </div>
    </div>);
});
export const containerStyle = css `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
  font-weight: 600;
`;
export const gridContainerStyle = css `
  display: grid;
  grid-template-rows: 50% 50%;
  grid-gap: 4px;
  height: 100%;
  width: 100%;
`;
export const highlightPositive = css `
  color: #27fa5f;
`;
export const highlightNegative = css `
  color: #ff7a7a;
`;
export const backgroundPositive = css `
  background-color: #00aa0029;
`;
export const backgroundDefault = css `
  &:hover {
    background-color: #232323;
  }
`;
export const backgroundNegative = css `
  background-color: #aa000029;
`;
export const limitUpStyle = css `
  background-color: #ae0000;
`;
export const limitDownStyle = css `
  background-color: #026e00;
`;
const errorStyle = css `
  color: #ff3333;
`;
const getPriceDiffPercentStyle = (tradingTicks, priceDiff) => {
    if (tradingTicks > 9 && priceDiff > 0) {
        return highlightPositive;
    }
    else if (tradingTicks > 9 && priceDiff < 0) {
        return highlightNegative;
    }
    return '';
};
const getPriceDiffPercentStyle2 = (tradingTicks, priceDiff) => {
    if (tradingTicks > 19 && priceDiff > 0) {
        return backgroundPositive;
    }
    else if (tradingTicks > 19 && priceDiff < 0) {
        return backgroundNegative;
    }
    return backgroundDefault;
};
const getIndexCloseStyle = (indexLimitUp, indexLimitDn, indexClose) => {
    if (indexLimitUp === indexClose) {
        return limitUpStyle;
    }
    else if (indexLimitDn === indexClose) {
        return limitDownStyle;
    }
    return '';
};
const getOrderDiffTicksStyle = (futuresOrderDiffTicks) => {
    if (futuresOrderDiffTicks > 6) {
        return errorStyle;
    }
    return '';
};
