import React, { useState, memo } from 'react';
// 定義國內選擇權相關的枚舉和類型
var OrderCategory;
(function (OrderCategory) {
    OrderCategory["TWOption"] = "\u570B\u5167\u9078\u64C7\u6B0A";
})(OrderCategory || (OrderCategory = {}));
var OptionType;
(function (OptionType) {
    OptionType["Single"] = "\u55AE\u5F0F";
    OptionType["Complex"] = "\u8907\u5F0F";
})(OptionType || (OptionType = {}));
var ComplexOrderType;
(function (ComplexOrderType) {
    ComplexOrderType["Bullish"] = "\u770B\u591A";
    ComplexOrderType["Bearish"] = "\u770B\u7A7A";
    ComplexOrderType["Breakout"] = "\u7A81\u7834";
    ComplexOrderType["Consolidation"] = "\u76E4\u6574";
})(ComplexOrderType || (ComplexOrderType = {}));
// 更新複式單子種類的正確英文名稱
var BullishOrderSubType;
(function (BullishOrderSubType) {
    BullishOrderSubType["CallCreditSpread"] = "\u8CB7\u6B0A\u7A7A\u982D\u50F9\u5DEE";
    BullishOrderSubType["PutCreditSpread"] = "\u8CE3\u6B0A\u591A\u982D\u50F9\u5DEE";
    BullishOrderSubType["ReverseCombo"] = "\u9006\u8F49\u7D44\u5408";
})(BullishOrderSubType || (BullishOrderSubType = {}));
var BearishOrderSubType;
(function (BearishOrderSubType) {
    BearishOrderSubType["PutDebitSpread"] = "\u8CE3\u6B0A\u7A7A\u982D\u50F9\u5DEE";
    BearishOrderSubType["CallDebitSpread"] = "\u8CB7\u6B0A\u591A\u982D\u50F9\u5DEE";
    BearishOrderSubType["ConversionCombo"] = "\u8F49\u63DB\u7D44\u5408";
})(BearishOrderSubType || (BearishOrderSubType = {}));
var BreakoutOrderSubType;
(function (BreakoutOrderSubType) {
    BreakoutOrderSubType["LongStraddle"] = "\u8CB7\u9032\u8DE8\u5F0F";
    BreakoutOrderSubType["LongStrangle"] = "\u8CB7\u9032\u52D2\u5F0F";
})(BreakoutOrderSubType || (BreakoutOrderSubType = {}));
var ConsolidationOrderSubType;
(function (ConsolidationOrderSubType) {
    ConsolidationOrderSubType["ShortStraddle"] = "\u8CE3\u51FA\u8DE8\u5F0F";
    ConsolidationOrderSubType["ShortStrangle"] = "\u8CE3\u51FA\u52D2\u5F0F";
})(ConsolidationOrderSubType || (ConsolidationOrderSubType = {}));
var OptionOrderType;
(function (OptionOrderType) {
    OptionOrderType["Auto"] = "\u81EA\u52D5";
    OptionOrderType["NewPosition"] = "\u65B0\u5009";
    OptionOrderType["ClosePosition"] = "\u5E73\u5009";
})(OptionOrderType || (OptionOrderType = {}));
var OptionEntrustType;
(function (OptionEntrustType) {
    OptionEntrustType["ROD"] = "ROD";
    OptionEntrustType["IOC"] = "IOC";
    OptionEntrustType["FOK"] = "FOK";
})(OptionEntrustType || (OptionEntrustType = {}));
var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Long"] = "\u591A";
    OrderDirection["Short"] = "\u7A7A";
})(OrderDirection || (OrderDirection = {}));
export const TWOptionOrder = memo(function TWOptionOrder() {
    const [orderParams, setOrderParams] = useState({
        optionType: OptionType.Single,
        account: '',
        product: [],
        orderType: OptionOrderType.Auto,
        entrustType: OptionEntrustType.ROD,
        quantity: 0,
        price: 0,
        orderDirection: OrderDirection.Long,
    });
    const handleInputChange = (field, value) => {
        setOrderParams((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    const handleComplexOrderTypeChange = (type) => {
        setOrderParams((prev) => ({
            ...prev,
            complexOrderType: type,
            complexOrderSubType: undefined, // Reset sub-type when main type changes
        }));
    };
    return (<div>
      <div>
        <label>選擇權種類:</label>
        <select value={orderParams.optionType} onChange={(e) => handleInputChange('optionType', e.target.value)}>
          {Object.values(OptionType).map((type) => (<option key={type} value={type}>
              {type}
            </option>))}
        </select>
      </div>
      
      {orderParams.optionType === OptionType.Complex && (<div>
          <label>複式單種類:</label>
          <select value={orderParams.complexOrderType} onChange={(e) => handleComplexOrderTypeChange(e.target.value)}>
            {Object.values(ComplexOrderType).map((type) => (<option key={type} value={type}>
                {type}
              </option>))}
          </select>
        </div>)}

      {orderParams.optionType === OptionType.Complex && orderParams.complexOrderType && (<div>
          <label>複式單子種類:</label>
          <select value={orderParams.complexOrderSubType} onChange={(e) => handleInputChange('complexOrderSubType', e.target.value)}>
            {orderParams.complexOrderType === ComplexOrderType.Bullish &&
                Object.values(BullishOrderSubType).map((type) => (<option key={type} value={type}>
                  {type}
                </option>))}
            {orderParams.complexOrderType === ComplexOrderType.Bearish &&
                Object.values(BearishOrderSubType).map((type) => (<option key={type} value={type}>
                  {type}
                </option>))}
            {orderParams.complexOrderType === ComplexOrderType.Breakout &&
                Object.values(BreakoutOrderSubType).map((type) => (<option key={type} value={type}>
                  {type}
                </option>))}
            {orderParams.complexOrderType === ComplexOrderType.Consolidation &&
                Object.values(ConsolidationOrderSubType).map((type) => (<option key={type} value={type}>
                  {type}
                </option>))}
          </select>
        </div>)}

      <div>
        <label>帳號:</label>
        <input type="text" value={orderParams.account} onChange={(e) => handleInputChange('account', e.target.value)}/>
      </div>
      <div>
        <label>商品搜尋:</label>
        <input type="text" value={orderParams.product.join(',')} onChange={(e) => handleInputChange('product', e.target.value.split(','))}/>
      </div>
      <div>
        <label>下單類型:</label>
        <select value={orderParams.orderType} onChange={(e) => handleInputChange('orderType', e.target.value)}>
          {Object.values(OptionOrderType).map((type) => (<option key={type} value={type}>
              {type}
            </option>))}
        </select>
      </div>
      <div>
        <label>委託類別:</label>
        <select value={orderParams.entrustType} onChange={(e) => handleInputChange('entrustType', e.target.value)}>
          {Object.values(OptionEntrustType).map((type) => (<option key={type} value={type}>
              {type}
            </option>))}
        </select>
      </div>
      <div>
        <label>數量:</label>
        <input type="number" value={orderParams.quantity} onChange={(e) => handleInputChange('quantity', Number(e.target.value))}/>
      </div>
      <div>
        <label>價格:</label>
        <input type="number" value={orderParams.price} onChange={(e) => handleInputChange('price', Number(e.target.value))}/>
      </div>
      <div>
        <label>下單方向:</label>
        <select value={orderParams.orderDirection} onChange={(e) => handleInputChange('orderDirection', e.target.value)}>
          {Object.values(OrderDirection).map((direction) => (<option key={direction} value={direction}>
              {direction}
            </option>))}
        </select>
      </div>
    </div>);
});
